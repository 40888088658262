<style lang="sass" scoped>
.table-item-container
  &:hover
    .table-item
      // opacity: 0.7
      &:hover
        // opacity: 1
        // border-top: solid 3px #ccc
        // border-bottom: solid 3px #ccc
        background-color: #fdffb6
        // background-color: #f0f0f0
        // text-decoration: underline

  .table-item-active
    background-color: #f0f0f0
    &:hover
      background-color: mix(#fdffb6, #f0f0f0, 50%) !important
.sticky-header
  position: sticky
  top: -1px
  background-color: #fff
  z-index: 1000
  // border-bottom: solid 2px #ccc
  outline: solid 1px #ccc
.link-customer-name
  font-weight: bold
  &:visited
    color: #7952b3
.status-memo
  white-space: pre-line
  word-break: break-all
  a
    white-space: pre-line
    word-break: break-all
</style>
<template lang="pug">
div.bg-light
  div.bg-white
    router-view
  form.p-3(@submit.prevent='search')
    .row
      .col
        button.btn.btn-primary.btn-sm.p-4.float-right(type='submit') 검색
        strong 상태
        b-form-radio-group(v-model='status_selected' :options='status_options')
        //- strong {{status_selected}}
        hr
        label 태그
        b-form-checkbox-group(v-model='tag_selected' :options='tag_options')
        //- strong {{tag_selected}}

      .col
        .row
          .col
            strong 고객검색
            input.form-control(type='text' v-model='search_customer' style='font-size: 12px' placeholder='고객명 연락처 이메일 카톡아이디')
            //- button.btn.btn-primary.btn-sm.px-4(type='button') 검색
            strong 주문검색
            input.form-control(type='text' v-model='search_order' style='font-size: 12px' placeholder='주문메모 커스텀필드')
          .col
            div.border.p-2.bg-white(style='font-size: 12px' v-if='$store.state.status_config_by_text && $store.state.status_config_by_text[search_state.status]')
              h5.border-bottom "{{search_state.status}}" 상태 메모
                router-link.float-right(:to='`/property/${property_id}/manage/status-flow`') 편집
              p.status-memo(v-html='status_memo')
            div.border.p-2.bg-white(style='font-size: 12px' v-if='$store.state.message_templates')
              h5.border-bottom "{{search_state.status}}" 상태에 보낼 메시지
                router-link.float-right(:to='`/property/${property_id}/manage/message-template`') 편집
              //- p.status-memo(v-html='status_memo')
              //- pre {{message_template_stat}}
              button(type='button' @click='open_sms_form()') 수기문자입력

              div(v-if='message_template_stat.loaded')
                div(v-for='template in $store.state.message_templates' v-if='template.target.status == search_state.status')
                  .card.shadow.p-2.m-2.card-checklist(style='font-size: 12px' @click='open_sms_template(template)')
                    strong {{template.name}}
                      span.float-right.text-muted(v-show='message_template_stat[template.id].remaining') {{ message_template_stat[template.id].remaining }}건 남음
                      span.float-right.text-muted(v-show='!message_template_stat[template.id].remaining') 완료
                    b-progress.border(class="mt-2" :max="message_template_stat[template.id].total" show-value)
                      b-progress-bar(:value="message_template_stat[template.id].sent" variant="success")
                      b-progress-bar(:value="message_template_stat[template.id].remaining" variant="light")
                  //- button.btn.btn-light.border.btn-block(type='button' @click='send_sms()') {{template.name}}
                    br
                    span {{message_template_stat[template.id].total}}건중 {{message_template_stat[template.id].sent}} 보냄
                    //- bu


  .p-2
    strong 검색조건
    //- span.ml-2(v-show='search_state.status') 상태 "{{search_state.status}}"
    //- span.ml-2(v-show='search_state.tags.length') 태그 "{{search_state.tags_csv}}"
    span.ml-2 상태 "{{search_state.status}}"
    span.ml-2 태그 "{{search_state.tags}}"
    span.ml-2 고객검색 "{{search_state.search_customer}}"
    span.ml-2 search_order "{{search_state.search_order}}"
  //- pre {{rows}}

  div.bg-white
    table.table.table-bordered
      thead
        tr
          th.sticky-header.shadow-sm 고객명
          th.sticky-header.shadow-sm 고객메모
          th.sticky-header.shadow-sm 상태
          th.sticky-header.shadow-sm 담당자 로그
          th.sticky-header.shadow-sm 분류
      tbody.table-item-container
        tr.table-item(style='height: 5rem;' v-for='row in rows' :class="{'table-item-active': (order_id == row.id)}")
          td
            router-link.link-customer-name(:to='link_view(row)' style='text-decoration: underline') {{row.name || '이름없음'}}
            br
            router-link(:to='`/property/${property_id}/views/page/${row.id}`'  style='font-size: 12px') 페이지
            br
            router-link(:to='`/property/${property_id}/views/page/${row.id}`' target='_blank'  style='font-size: 12px') 새창
          td.p-0
            textarea.form-control.border-0.m-0(style='background-color:rgba(0,0,0,.05); font-size: 11px; height: 5rem' v-model='row.memo')
          td.p-0
            //- div.bg-success.text-white.text-center(style='line-height: 2rem') 체크인
            select.form-control.border-0(style='' v-model='row.status_text' @blur='update_status(row)')
              option(v-for='sf in status_flows' :value='sf') {{sf}}
          td.p-0(style='font-size: 11px;')
            div.pb-3(style='overflow: scroll; height: 5rem')
              .border-bottom
                textarea.form-control.border-0.m-0(style='background-color:rgba(0,0,0,.05); font-size: 11px; height: 2rem' placeholder='메모입력')
              .border-bottom(v-for='comment in row.comments')
                strong {{comment.user_name || '(이름없음)'}}
                span.ml-1.text-muted.float-right {{comment.created_at}}
                p.mb-0 {{comment.origin_text}}

              .text-center
                span 고객 {{row.created_at}} 추가됨 (from 수기입력)

          td.p-0(style='max-width: 200px')
            //- p {{row.tags}}
            //- p {{row.tags_selected}}
            vue-tags-input(
              v-model='row.tag'
              :tags='row.tags_selected'
              @tags-changed='newTags => { row.tags_selected = newTags; tags_changed(row) }'
              :autocomplete-items='filteredItems(row.tag)'
              @blur='tag_blured(row)'
            )


    .border
      code autocompleteItems= {{autocompleteItems}} {{autocompleteItemsCho}}

</template>

<script>

import moment from 'moment'

import VueTagsInput from '@johmun/vue-tags-input'

import * as hangul from 'hangul-js'

import compact from 'lodash/compact'
import isObject from 'lodash/isObject'
import isEqual from 'lodash/isEqual'
import isString from 'lodash/isString'

import linkifyStr from 'linkifyjs/string'

export default {
  name: 'index',
  props: ['property_id', 'sf_id', 'order_id'],
  components: {
    VueTagsInput,
  },
  computed: {
    // session() {
    //   return this.$store.state.session
    // },
    status_memo() {
      if (this.$store.state.status_config_by_text[this.search_state.status]?.memo) {
        return linkifyStr(this.$store.state.status_config_by_text[this.search_state.status].memo)
      } else {
        return ''
      }
    },
    status_flows() {
      return this.$store.state.status_flows
    },
    status_flows_counts_by_status() {
      return this.$store.state.status_flows_counts_by_status
    },
    status_flows_counts_color_by_status() {
      return this.$store.state.status_flows_counts_color_by_status
    },
    status_flows_counts_total() {
      return this.$store.state.status_flows_counts_total
    },
    current_path() {
      return decodeURIComponent(this.$route.path)
    },
    // filteredItems() {
    //   return this.autocompleteItems.filter((e, i) => {
    //     const cho = hangul.d(this.tag, true).map(e => e[0]).join('')
    //     // console.log(cho, this.autocompleteItemsCho[i].includes(cho))

    //     return e.text.toLowerCase().includes(this.tag.toLowerCase()) || this.autocompleteItemsCho[i].includes(cho)
    //     //  || this.autocompleteItemsCho[i].indexOf(cho)
    //   })
    // }
  },
  async mounted() {
    await this.$store.dispatch('users by id', this.property_id)
    await this.load()

  },
  watch: {
    '$route.query.q' (after, before) {
      if (after) {
        console.log('q changed')
        this.load_search_params(after)
        this.fetch_order()
      }
    },
    '$store.state.tags' (after) {
      console.log('$store.state.tags', this.$store.state.tags)

      this.tag_options = this.$store.state.tags.map(e => {
        return {
          text: e,
          value: e,
        }
      })

      this.autocompleteItems = this.$store.state.tags.map(e => {
        return {text: e}
      })
      console.log('this.autocompleteItems', this.autocompleteItems)
      this.autocompleteItemsCho = this.$store.state.tags.map(e => {
        const cho = hangul.d(e, true).map(e => e[0]).join('')
        return cho
      })
    }
  },
  data() {
    return {
      tag_selected: [],
      tag_options: [],
      status_selected: '',
      status_options: [],
      tag: '',
      autocompleteItems: [],
      autocompleteItemsCho: [],
      tags: [],
      search_customer: '',
      search_order: '',
      rows: [],

      search_state: {
        status: '',
      },
      loading: true,

      message_template_stat: {
        loaded: false
      },

    }
  },
  methods: {
    async load() {
      try {
        await this.$store.dispatch('status flows count', this.property_id)

        // this.load_tags()
        this.status_options = ['전체'].concat(this.$store.state.status_flows).map(e => {
          return {
            text: e,
            value: e,
          }
        })

        // load saved state
        if (this.$route.query.q) {
          this.load_search_params(this.$route.query.q)
        }
        // console.log(this.$route.query.q)

        // set default search
        this.search_state.status = '전체'
        console.log('set default search', {...this.search_state})
        if (!this.status_selected)
          this.status_selected = '전체'

        await this.fetch_order()
      } catch (error) {
        console.log({error})
      }
    },

    async fetch_order() {
      this.loading = true
      try {
        let tags = []
        console.log(this.tag_selected)
        console.log('this.status_selected', this.status_selected)
        if (this.tag_selected.length == this.tag_options.length) {
          // ALL
        } else {
          tags = this.tag_selected
        }

        let status = []
        if (this.status_selected == '전체') {
          // ALL
        } else {
          status = this.status_selected
        }
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/orders`, {
          params: {
            tags,
            status,
            search_customer: this.search_customer,
            search_order: this.search_order,
          }
        })
        if (r?.data?.message != 'ok') throw new Error('가져오기 실패')

        this.rows = r.data.orders.map(e => {
          e.tag = ''
          e.tags_prev = e.tags
          e.tags = compact((e.tags || '').split('\n'))
          e.tags_selected = e.tags.map(e => {
            return {
              text: e,
              tiClasses: ['ti-valid'],
            }
          })
          e.status_text_prev = e.status_text
          return e
        })

        this.load_message_template_stat()
        this.loading = false
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },


    load_message_template_stat() {
      const total = this.rows.length

      for (const e of this.$store.state.message_templates) {
        let sent = 0

        for (const row of this.rows) {
          if (row.stat && row.stat.sent_message_template_ids && row.stat.sent_message_template_ids.includes(e.id)) {
            sent++
          }
        }

        this.message_template_stat[e.id] = {
          total,
          sent,
          remaining: total-sent,
        }
      }

      this.message_template_stat.loaded = true
    },

    link_view(row) {
      if (this.$route.query?.q) {
        return `/property/${this.property_id}/views/${row.id}?q=${this.$route.query.q}`
      }
      return `/property/${this.property_id}/views/${row.id}`
    },

    load_search_params(string) {
      if(string) {
        if (isString(string)) {
          this.search_state = JSON.parse(string)
        } else {
          this.search_state = string
        }
        console.log('NEW this.search_state', this.search_state)
        this.tag_selected = this.search_state.tags
        this.status_selected = this.search_state.status
        this.search_customer = this.search_state.search_customer
        this.search_order = this.search_state.search_order
        console.log('NEW SET', [this.tag_selected, this.status_selected, this.search_customer, this.search_order])
      }
    },

    search() {
      this.search_state = {
        status: this.status_selected,
        tags: this.tag_selected,
        search_customer: this.search_customer,
        search_order: this.search_order,
      }

      const q = JSON.stringify(this.search_state)
      let qq;
      console.log('>>>>', this.$route.query.q)
      if (isString(this.$route.query.q)) {
        qq = this.$route.query.q
      } else {
        console.log(5)
        qq = JSON.stringify(this.$route.query.q)
      }

      if (qq != q || this.$route.params.order_id) {
        // search state changed OR escape order detail page
        console.log(33, qq, q)
        this.$router.push({
          path: `/property/${this.property_id}/views`,
          query: {
            q,
          }
        })
      } else {
        this.fetch_order()
      }
    },

    filteredItems(tag) {
      return this.autocompleteItems.filter((e, i) => {
        const cho = hangul.d(tag, true).map(e => e[0]).join('')
        // console.log(cho, this.autocompleteItemsCho[i].includes(cho))

        return e.text.toLowerCase().includes(tag.toLowerCase()) || this.autocompleteItemsCho[i].includes(cho)
        //  || this.autocompleteItemsCho[i].indexOf(cho)
      })
    },

    tags_changed(row) {
      console.log('tags_changed', row.id)
    },

    async tag_blured(row) {
      console.log('tag_blured')

      row.tags_next = '\n'+row.tags_selected.map(e => e.text).join('\n')+'\n'
      if (row.tags_prev == row.tags_next) {
        return
      }

      try {
        const r = await this.$http.put(`/v1/property/${this.property_id}/views/orders/${row.id}/tags`, {
          tags_next: row.tags_next,
        })
        if (r?.data?.message != 'ok') throw new Error('저장 실패')

        this.$bvToast.toast(`태그 저장 ${moment().format('HH:mm:ss')}`, {
          variant: 'default',
          solid: true,
          noCloseButton: true,
        })
        this.$store.dispatch('tags', this.property_id)
        // this.
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

    async update_status(row) {
      try {
        if (row.status_text == row.status_text_prev) return false
        // if(!confirm(`Proceed? \n\nCurrent: ${row.status_text_prev}\nUpdate: ${row.status_text}`)) {
        //   row.status_text = row.status_text_prev
        //   return false
        // }
        const r = await this.$http.put(`/v1/property/${this.property_id}/views/orders/${row.id}/status_text`, {
          status_text: row.status_text,
        })
        if (r?.data?.message != 'ok') throw new Error('저장 실패')

        row.status_text_prev = row.status_text

        this.$bvToast.toast(`status changed`, {
          variant: 'default',
          solid: true,
          noCloseButton: true,
        })
      } catch (error) {
        alert(error.stack)
      }
    },

    open_sms_form() {
      // alert('page with single form')
      this.$router.push({
        path: `/property/${this.property_id}/views/message/new`,
        query: {
          oids: JSON.stringify(this.rows.map(e => e.id)),
          // template_id: template.id,
        },
      })
    },
    open_sms_template(template) {
      // alert('page with orderids, templateid')
      this.$router.push({
        path: `/property/${this.property_id}/views/message/new`,
        query: {
          oids: JSON.stringify(this.rows.map(e => e.id)),
          template_id: template.id,
        },
      })
    },

  },
}
</script>
